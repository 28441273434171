import React, { useState } from "react"
import LazyLoad from "react-lazyload"

import NextButton from "../core/buttons/NextButton"
import SiteScore from "./SiteScore"
import DevContent from "./DevContent"
import DaveContent from "./DaveContent"
import Projects from "../Projects"
import ThisSite from "../ThisSite"
import { Row, Col, Container } from "../core/Grid"
import {
  Dot,
  ContentWrap,
  ProjectText,
  DaveFlowers,
  ProjectRow,
  HeroColLeft,
  HeroColRight,
  HeroWrap,
  LighthouseRow,
  CloseButton,
  MobileSeeWorkButton,
} from "./HomeStyles"

interface HomeProps {
  props: any
}

const Home: React.FC<HomeProps> = ({ ...props }) => {
  const [about, setAbout] = useState(false)
  const [showProjects, setShowProjects] = useState(false)

  return (
    <>
      <Row>
        <Container width="1200px">
          <HeroWrap align="center">
            <HeroColLeft width="50%" justify="center">
              <ContentWrap>
                {about ? <DaveContent /> : <DevContent />}
                <NextButton
                  handleClick={() => setAbout(!about)}
                  lightMode={props.props}
                  buttonText={`${about ? "less" : "more"} about me`}
                />
              </ContentWrap>
            </HeroColLeft>
            <HeroColRight width="50%" justify="center" align="center">
              <MobileSeeWorkButton
                lightMode={props.props}
                onClick={() => setShowProjects(!showProjects)}
              >
                <a href="#work">
                  <span>&#xbb;&nbsp;</span>
                  {showProjects ? "see less of my work" : "see some of my work"}
                </a>
              </MobileSeeWorkButton>
              <Dot lightMode={props.props}>
                <DaveFlowers lightMode={props.props} />
                <ProjectText
                  lightMode={props.props}
                  onClick={() => setShowProjects(!showProjects)}
                >
                  <a href="#work">
                    <span>&#xbb;&nbsp;</span>
                    {showProjects
                      ? "see less of my work"
                      : "see some of my work"}
                  </a>
                </ProjectText>
              </Dot>
            </HeroColRight>
          </HeroWrap>
        </Container>
      </Row>
      <ProjectRow
        lightMode={props.props}
        showProjects={showProjects}
        about={about}
        smP="3rem 0 4px"
        p="4rem 0 16px"
        id="work"
      >
        <Container width="1200px">
          <ThisSite lightMode={props.props} />
          <LighthouseRow p="1rem 0 4rem" align="center">
            <LazyLoad>
              <Col width="33%" align="center">
                <img
                  width="200"
                  src="./lighthouse.png"
                  alt="Google Lighthouse logo"
                />
                <p>Google Lighthouse Score:</p>
              </Col>
            </LazyLoad>
            <Col width="66%" justify="center" align="center">
              <Row align="center" justify="space-between">
                <SiteScore lightMode={props.props} />
              </Row>
            </Col>
          </LighthouseRow>
          <Projects lightMode={props.props} />
        </Container>
        <CloseButton
          lightMode={props.props}
          onClick={() => setShowProjects(false)}
        >
          <p>
            <span>&#xbb;&nbsp;</span>
            close this section
          </p>
        </CloseButton>
      </ProjectRow>
    </>
  )
}

export default Home

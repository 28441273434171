import React from "react"

import SEO from "../components/SEO"
import Layout from "../components/core/Layout"
import Home from "../components/Home"

export default () => {
  return (
    <>
      <SEO />
      <Layout>
        <Home props />
      </Layout>
    </>
  )
}

import styled, { keyframes } from "styled-components"
import { fadeInUp, fadeOutDown } from "react-animations"

import { Row, Col } from "./../core/Grid"
import colors from "../core/styles/colors"

const fadeInAnimation = keyframes`
  ${fadeInUp}
`
const fadeOutAnimation = keyframes`
  ${fadeOutDown}
`

interface Props {
  lightMode: boolean
  about: boolean
  showProjects: boolean
  showBooks: boolean
}

export const Dot = styled.div<Props>`
  display: none;
  height: 175px;
  width: 175px;
  border-radius: 50%;
  background: ${({ lightMode }) =>
    lightMode ? colors.tagline : colors.lightSecondary};
  z-index: 0;
  position: absolute;
  right: 0;

  @media (min-width: 768px) {
    display: block;
    height: 250px;
    width: 250px;
  }
  @media (min-width: 1024px) {
    height: 500px;
    width: 500px;
    position: relative;
  }
`

export const MobileSeeWorkButton = styled.div`
  background: ${({ lightMode }) =>
    lightMode ? colors.tagline : colors.lightSecondary};
  padding: 12px 24px;
  border-radius: 60px;

  a {
    margin: 0;
    font-weight: 600;
    font-size: 18px;
    cursor: pointer;

    color: ${({ lightMode }) => (lightMode ? "#fff" : colors.tagline)};
    text-decoration: none;

    &:hover {
      color: ${({ lightMode }) =>
        lightMode ? colors.primary : colors.complimentary};
    }
    span {
      color: ${({ lightMode }) =>
        lightMode ? colors.primary : colors.complimentary};
      font-weight: 800;
    }
  }

  @media (min-width: 768px) {
    display: none;
  }
`

export const DaveBubblegum = styled.div<Props>`
  position: absolute;
  bottom: -88px;
  right: 28%;
  width: 400px;
  height: 700px;
  background: url("./david-shirt-full.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
`
export const DaveFlowers = styled.div<Props>`
  display: none;
  position: absolute;
  bottom: 16%;
  right: 10%;
  width: 200px;
  height: 200px;
  background: url("./david-flowers.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;

  @media (min-width: 768px) {
    display: block;
    width: 300px;
    height: 300px;
  }
  @media (min-width: 1024px) {
    width: 400px;
    height: 534px;
    bottom: 4%;
    right: 30%;
  }
`

export const HeroWrap = styled(Row)`
  padding: 2rem 1rem 0.5rem;
  min-height: calc(100vh - 188px);
  @media (min-width: 1024px) {
    padding: 0;
    height: calc(100vh - 189px);
    min-height: 650px;
  }
`

export const HeroColLeft = styled(Col)`
  z-index: 3;
  @media (min-width: 1024px) {
    align-items: unset;
  }
`

export const HeroColRight = styled(Col)`
  min-height: 150px;
  @media (min-width: 1024px) {
    min-height: 190px;
    align-items: flex-end;
  }
`

export const ContentWrap = styled.div<Props>``

export const ProjectText = styled.div<Props>`
  position: absolute;
  bottom: 15%;
  right: 30px;
  z-index: 1;
  width: 121%;
  padding: 1rem;
  background: ${({ lightMode }) =>
    lightMode ? colors.secondary : colors.lightPrimary};
  border-radius: 60px;
  a {
    margin: 0;
    font-weight: 600;
    font-size: 18px;
    cursor: pointer;

    color: ${({ lightMode }) => (lightMode ? "#fff" : colors.tagline)};
    text-decoration: none;

    &:hover {
      color: ${({ lightMode }) =>
        lightMode ? colors.primary : colors.complimentary};
    }
    span {
      color: ${({ lightMode }) =>
        lightMode ? colors.primary : colors.complimentary};
      font-weight: 800;
    }
  }

  @media (min-width: 1024px) {
    transform: none;
    width: fit-content;
    padding: 1.25rem 2rem;
    left: 50%;
    right: auto;
  }
`

export const ProjectRow = styled(Row)<Props>`
  background: ${({ lightMode }) =>
    lightMode ? colors.tagline : colors.lightSecondary};
  display: ${({ showProjects }) => (showProjects ? "block" : "none")};
  /* display: ${({ showProjects, about }) =>
    showProjects && !about ? "block" : "none"}; */
  border-bottom: 1px solid
    ${({ lightMode }) => (lightMode ? "#fff" : colors.lightPrimary)};
`

export const BooksRow = styled(Row)<Props>`
  background: ${({ lightMode }) =>
    lightMode ? colors.tagline : colors.lightSecondary};
  display: ${({ showBooks, about }) => (showBooks && about ? "block" : "none")};
`

export const LighthouseRow = styled(Row)<Props>`
  border-bottom: 1px solid
    ${({ lightMode }) => (lightMode ? "#fff" : colors.lightPrimary)};

  p {
    margin: 0;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    p {
      margin-bottom: 2rem;
    }
  }

  @media (max-width: 767px) {
    padding: 0 0 3rem;

    img {
      width: 150px;
    }
  }
`

export const CloseButton = styled.div<Props>`
  position: relative;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  width: fit-content;
  padding: 1.25rem 2rem;
  cursor: pointer;

  p {
    margin: 0;
    font-weight: 600;
    font-size: 18px;
    padding: 1rem;
    &:hover {
      color: ${({ lightMode }) =>
        lightMode ? colors.primary : colors.complimentary};
    }
    span {
      color: ${({ lightMode }) =>
        lightMode ? colors.primary : colors.complimentary};
      font-weight: 800;
    }
  }
`

import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"
import LazyLoad from "react-lazyload"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons"

import { Col, Row } from "../core/Grid"
import colors from "../core/styles/colors"

interface ProjectsProps {
  lightMode: boolean
}

const ProjectWrap = styled(Col)<ProjectsProps>`
  background: ${({ lightMode }) =>
    lightMode ? colors.secondary : colors.lightPrimary};
  margin: 1% 0;

  @media (min-width: 768px) {
    margin-right: 2%;
    &:nth-child(even) {
      margin-right: 0;
    }
  }
  @media (min-width: 1024px) {
    &:nth-child(even) {
      margin-right: 2%;
    }
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
  @media (max-width: 767px) {
    flex: 0 0 auto;
    margin: 1%;
  }

  .title {
    text-transform: uppercase;
    margin: 0;
    line-height: 1;
  }

  a {
    color: ${({ lightMode }) => (lightMode ? "#fff" : colors.tagline)};
    text-decoration: none;
  }

  svg {
    color: ${({ lightMode }) =>
      lightMode ? colors.primary : colors.complimentary};
    font-size: 14px;
    padding-bottom: 2px;
    margin-bottom: 3px;
  }

  hr {
    border: 2px solid
      ${({ lightMode }) => (lightMode ? colors.primary : colors.complimentary)};
    width: 60px !important;
    margin: 16px 0 !important;
  }

  .content {
    padding: 0 1rem 1rem;
    width: 100%;
  }

  span {
    color: ${({ lightMode }) =>
      lightMode ? colors.primary : colors.complimentary};
    text-transform: lowercase;
    margin-right: 12px;
    &:last-of-type {
      margin-right: 0;
    }
  }
`

const ProjRow = styled(Row)`
  @media (max-width: 767px) {
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`

const Projects: React.FC<ProjectsProps> = ({ lightMode }) => (
  <StaticQuery
    query={graphql`
      {
        allWpPost {
          nodes {
            title
            slug
            excerpt
            featuredImage {
              node {
                sourceUrl
                altText
              }
            }
            categories {
              nodes {
                name
              }
            }
          }
        }
      }
    `}
    render={data => (
      <LazyLoad>
        <ProjRow smP="3rem 0 0" p="4rem 0 0" justify="flex-start">
          {data.allWpPost.nodes.map(i => (
            <ProjectWrap
              key={i.title}
              lightMode={lightMode}
              smWidth="90%"
              mdWidth="49%"
              width="32%"
              align="center"
            >
              <img
                src={i.featuredImage.node.sourceUrl}
                alt={i.featuredImage.node.altText}
              />
              <div className="content">
                <a href={i.excerpt} target="_blank">
                  <Row align="center" m="24px 0 0">
                    <h4
                      className="title"
                      dangerouslySetInnerHTML={{
                        __html: `${i.title}&nbsp;&nbsp;`,
                      }}
                    />
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </Row>
                </a>

                <hr />
                <Row align="center">
                  <Col smWidth="20%">skills:</Col>
                  <Col smWidth="80%">
                    <Row>
                      {i.categories.nodes.map(cat => (
                        <span key={cat.name}>{cat.name}</span>
                      ))}
                    </Row>
                  </Col>
                </Row>
              </div>
            </ProjectWrap>
          ))}
        </ProjRow>
      </LazyLoad>
    )}
  />
)

export default Projects

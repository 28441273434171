import React from "react"
import styled, { keyframes } from "styled-components"

import { LIGHTHOUSE_DATA } from "../../data/lighthouse"
import colors from "../core/styles/colors"
import { Col, Row } from "../core/Grid"

interface Props {
  fillAmount?: string
  lightMode?: boolean
}

const fill = keyframes<Props>`
  0% {
      transform: rotate(0deg);
    }
  100% {
    transform: rotate(calc(180deg * ${({ fillAmount }) => fillAmount}));
  }
`

const Circle = styled.div<Props>`
  width: 150px;
  height: 150px;
  background: ${({ lightMode }) =>
    lightMode ? colors.secondary : colors.lightSecondary};
  border-radius: 50%;
  margin-right: 4rem;
  &:last-of-type {
    margin-right: 0;
  }

  .circle .mask,
  .circle .fill {
    width: 150px;
    height: 150px;
    position: absolute;
    border-radius: 50%;
  }

  .circle .mask {
    clip: rect(0px, 150px, 150px, 75px);
  }

  .circle .mask .fill {
    clip: rect(0px, 75px, 150px, 0px);
    background-color: ${({ lightMode }) =>
      lightMode ? colors.primary : colors.complimentary};
  }

  .circle .mask.full,
  .circle .fill {
    animation: ${fill} 3s ease-in-out;
    transform: rotate(calc(180deg * ${({ fillAmount }) => fillAmount}));
  }

  .inside-circle {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background: ${({ lightMode }) =>
      lightMode ? colors.tagline : colors.lightPrimary};
    line-height: 130px;
    text-align: center;
    margin-top: 10px;
    margin-left: 10px;
    position: absolute;
    z-index: 100;

    font-size: 2em;
    color: ${({ lightMode }) => (lightMode ? colors.primary : colors.tagline)};
  }

  @media (max-width: 767px) {
    transform: scale(0.5);
  }
`
const Title = styled.span`
  position: absolute;
  left: 50%;
  top: 63%;
  transform: translateX(-50%);
  font-size: 14px;
  z-index: 101;

  @media (max-width: 767px) {
    top: 80%;
    font-size: 12px;
    text-align: center;
  }
`

const SiteScore: React.FC<Props> = ({ lightMode }) => (
  <Row align="center">
    {LIGHTHOUSE_DATA.map(i => (
      <Col
        key={i.id}
        smWidth="25%"
        mdWidth="25%"
        align="center"
        justify="center"
      >
        <Circle lightMode={lightMode} fillAmount={i.score}>
          <div className="circle">
            <div className="mask full">
              <div className="fill"></div>
            </div>
            <div className="mask half">
              <div className="fill"></div>
            </div>
            <div className="inside-circle">{i.score * 100}</div>
          </div>
        </Circle>
        <Title>{i.title}</Title>
      </Col>
    ))}
  </Row>
)

export default SiteScore

import React from "react"
import styled from "styled-components"
import colors from "../styles/colors"

interface StyleProps {
  lightMode?: boolean
}

const ButtonText = styled.p<StyleProps>`
  font-size: 18px;
  font-weight: 800;
  width: fit-content;
  text-transform: lowercase;

  &:hover {
    cursor: pointer;
    color: ${({ lightMode }) =>
      lightMode ? colors.primary : colors.complimentary};
  }

  span {
    color: ${({ lightMode }) =>
      lightMode ? colors.primary : colors.complimentary};
    font-weight: 800;
  }
`

interface NextButtonProps {
  buttonText: string
  lightMode: boolean
  handleClick: any
}

const NextButton: React.FC<NextButtonProps> = ({
  lightMode,
  buttonText,
  handleClick,
}) => (
  <ButtonText onClick={handleClick} lightMode={lightMode}>
    <span>&#xbb;</span> {buttonText}
  </ButtonText>
)

export default NextButton

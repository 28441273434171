import React from "react"
import styled from "styled-components"

import { Row, Container, Col } from "../core/Grid"
import colors from "../core/styles/colors"

interface Props {
  lightMode: boolean
}

const ThisSiteContainer = styled(Container)`
  p {
    margin: 0;
    font-size: 1.1rem;
    font-weight: 800;
    text-transform: uppercase;
  }

  span {
    color: ${({ lightMode }) =>
      lightMode ? colors.primary : colors.complimentary};
    font-weight: 800;
  }

  hr {
    margin: 1rem 0 !important;
    width: 60px !important;
    @media (min-width: 768px) {
      margin: 1rem 0 0 !important;
    }
  }
`

const ThisSite: React.FC<Props> = ({ lightMode }) => (
  <ThisSiteContainer width="800px" lightMode={lightMode}>
    <Row align="center" smP="1rem 0 2rem" p="3rem 0 2rem">
      <Col mdWidth="30%">
        <p>About this site:</p>
        <hr />
      </Col>
      <Col mdWidth="70%">
        <div>
          This site is a <span>Headless CMS</span> built with <span>React</span>{" "}
          and <span>WordPress</span>, leveraging frameworks and technologies
          such as <span>Gatsby.js</span>, <span>GraphQL</span>, and{" "}
          <span>TypeScript</span> to make development easier, more powerful, and
          fun.
        </div>
      </Col>
    </Row>
  </ThisSiteContainer>
)

export default ThisSite

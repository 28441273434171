import React from "react"

const DaveContent = () => (
  <>
    <h1>
      Sailor
      <br />
      Jiujiteiro
    </h1>
    <hr />
    <p className="homePageP">
      When I'm not coding on the job, I spend a considerable amount of time
      tinkering with new technologies, training Jiu Jitsu, and dabbling in Thai
      boxing. I also enlisted in the United States Navy at the age of 38. I am
      in the process of building a blog, so more on that to come.
    </p>
  </>
)

export default DaveContent

import React from "react"

const DevContent = () => (
  <>
    <h1>
      Designer
      <br />
      Developer
      <br />
      Engineer
    </h1>
    <hr />
    <p className="homePageP">
      Hello! My name is Dave, and I am a Boston based web developer/UX Engineer.
    </p>
  </>
)

export default DevContent
